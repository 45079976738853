html {
    background-color: #1A1C18;
}

body {
    /*background-color: transparent;*/
    position: relative;
    background-color: #131412;
    color: #fff;
    font-family: 'Montserrat', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
}

.house-cleaning-slider.owl-carousel.owl-drag .owl-item {
    height: 306px;
    display: flex;
    align-items: flex-end;
}

.border-line {
    position: relative;
}

.border-line:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0;
    background-color: #FFED00;
}

.border-line--r:after {
    right: -10px;
}

.border-line--t:after {
    top: -15px !important;
}

.infotext-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 954px;
    padding: 0 15px;
    /* text-align: center; */
}

a,
a:hover,
a:visited,
a:active {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    // font-size: 16px;
}

p {
    margin: 0;
    padding: 0
}

h3 {
    font-weight: 500;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    color: #F0E947;
    padding-top: 70px;
}

.b1,
.b2,
.b3,
.b4,
.b5,
.b6,
.b7,
.b8 {
    position: relative;
}

.nowrap {
    white-space: nowrap;
}

/* Bootstrap containers */

.b1 {
    height: 900px;
    /*background: #131412 url("../../images/background-b1.webp") center 0px no-repeat;*/
}

.b2l1 {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 1167px;
    background: url("../../images/b2l1.webp") no-repeat 0 0;
    background-size: cover;
}

.b2l2 {
    position: absolute;
    right: 0px;
    top: 20px;
    width: 414px;
    height: 457px;
    background: linear-gradient(180deg, #131412 0%, #131412 0.01%, rgba(19, 20, 18, 0) 82.71%);
    /*transform: rotate(90deg);*/
    z-index: 1200;
}

.b2l3 {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 871px;
    height: 414px;
    background: url("../../images/b2l3.webp") no-repeat 0 0;
}

.b2l4 {
    position: absolute;
    left: 0px;
    top: 447px;
    width: 100%;
    height: 720px;
    background: radial-gradient(33.12% 36.46% at 60% 45%, #00713D 11.45%, #131412 98.13%);
    opacity: 0.5;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.b3 h3 {
    font-weight: 500;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    color: #F0E947;
    padding: 60px 0 15px 0;
}

.f2 p {
    position: relative;
    top: -12px;
}

.f3 h4 {
    padding: 40px 0 45px;
    text-align: center;
}

.cat-item p {
    margin: 10px 0 20px 0;
}

.b5-logo {
    margin-top: 100px;
    display: block;
}

.b5-header-letu {

    font-size: 36px;
    line-height: 1.33em;

}

.b5-header-letu .hl-name {
    font-weight: 800;
    font-size: 36px;
    text-transform: uppercase;
    color: #F0E947;
    letter-spacing: .08em;
    white-space: nowrap;
}

.no-more-dirt {
    line-height: 28px;
    /* padding: 20px 15px 20px 20px; */
    letter-spacing: .02rem;
    width: auto;
    font-weight: bold;
    font-size: 22px;
    background: linear-gradient(90deg, #F0E947 0%, #24A947 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* color: #F0E947; */
    /* margin: 0 0 0 10px; */
    width: max-content;
}

.b6 .nav-pills .tab-nav-link {
    padding: 12px 15px;
    font-size: 24px;
    font-weight: 600;
    text-transform: none;
    display: block;
}

.b6 .nav-pills .tab-nav-link.active,
.nav-pills .show>.tab-nav-link {
    background: linear-gradient(90deg, #EEE90E 1.9%, #24A947 101.9%);
}

.b6 ul.nav {
    width: max-content;
    margin: auto;
}

.b6 .tab-nav-link.active {
    color: #131412;
}

.b6 .nav-pills a,
.b6 .nav-pills .show>.tab-nav-link {
    color: #929292;
}

/*.b6 h3 */
/* .retailers-header {
    padding-top: 180px;
} */

.carousel .carousel-item {
    height: 120px;
    /*border: 1px solid aliceblue;*/
}

#carouselOfflineRetailers .owl-stage .owl-item:nth-child(8) .retailerItem img,
.carousel .carousel-item:nth-child(17) .retailerItem:nth-child(18) img {
    filter: grayscale(100%) brightness(1);
}

#carouselOfflineRetailers .owl-stage .owl-item:nth-child(8):hover img {
    filter: grayscale(0%) brightness(1);
}

.carousel .carousel-item a {
    display: contents;
    /*border: 1px solid red;*/
    height: 120px;
    margin: 10px;
}

.carousel-control-prev-icon {
    background-image: url("../../images/ui-carousel-retailers/b6-carousel-prev.webp");
}

.carousel-control-prev-icon:hover {
    background-image: url("../../images/ui-carousel-retailers/b6-carousel-prev-hover.webp");
}

.carousel-control-next-icon {
    background-image: url("../../images/ui-carousel-retailers/b6-carousel-next.webp");
}

.carousel-control-next-icon:hover {
    background-image: url("../../images/ui-carousel-retailers/b6-carousel-next-hover.webp");
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    width: 42px;
    height: 75px;
}

.retailerItem img {
    filter: grayscale(100%) brightness(2);
    margin: 10px;
    max-width: 200px;
}

.carousel .carousel-item:nth-child(3) .retailerItem:nth-child(2):hover img,
.retailerItem:hover img {
    filter: grayscale(0%);
    /* animation: retailerItem-img-animation .3s ease-in-out; */
    transition: .2s ease-in-out;
}

@keyframes retailerItem-img-animation {
    0% {
        filter: grayscale(100%);
    }

    100% {
        filter: grayscale(0%);
    }
}


.f5-lenka-retina {
    position: absolute;
    display: block;
    bottom: -15px;
    left: 49px;
    width: 419px;
    height: 337px;
}

.f5-lenka-retina img {
    height: 366px;
}

.b8 {
    padding: 40px 0;
}

.b6 p,
.b8 p {
    line-height: 1.5em;
    padding: 0px 0px 10px;
}

.b8 .logo {
    height: 75px;
}

/* top-trinity + elena PNG */
.top-trinity {
    position: absolute;
    top: 0px;
    width: auto;
    margin: 0 auto;
}

.tt {
    position: relative;
    float: left;
}

.tt1 {
    background: url("../../images/3D_Expel_SprayForWindows_Mirrors_191119copy1_5.webp") no-repeat;
    width: 176px;
    height: 455px;
    z-index: 2;
}

.tt2 {
    background: url("../../images/expel_toilet_gel_preview_face_6_1_1.webp") no-repeat;
    width: 185px;
    height: 545px;
}

.tt3 {
    background: url("../../images/expel_volosogon_preview_face_13-01_4.webp") no-repeat;
    width: 287px;
    height: 436px;
}

/* fast pre-comp mixd */

.ttm {
    margin: 0 auto;
    position: absolute;
    /*top: 278px;*/
    /*left: 238px;*/
    /*width: 723px;*/
    /*height: 568px;*/
}



.t1s1 {
    margin: 0;
    padding: 0px 0 8px 0;
    line-height: 1.23em;
    text-transform: uppercase;
    letter-spacing: .01rem;
}

.t1s2 {
    padding-top: 45px;
    text-transform: uppercase;
    color: #F0E947;
    letter-spacing: 0rem;

}

.t1s3 {
    width: 273px;
    font-size: 28px;
    line-height: 34px;
    padding: 20px 0px 20px 25px;
    letter-spacing: .02rem;
    margin: 69px 0;
}

.t1s2w1 {
    font-size: 60px;
    line-height: 68px;
}

.t1s2w2 {
    font-size: 45px;
    line-height: 52px;
    letter-spacing: 0.04rem;
}

.t1s2w3 {
    font-size: 27px;
    line-height: 40px;
    letter-spacing: 0.013rem;
    font-weight: 600;
}

/* 2 about */
.t2s1 {
    font-size: 48px;
    line-height: 59px;
    color: #F0E947;
    padding-top: 165px;
}

.t2s1-xs {
    color: #F0E947;
    font-size: 20px;
    line-height: 24px;
}

.t2s2 {
    font-size: 18px;
    line-height: 22px;
    padding-top: 25px;
    padding-bottom: 42px;
}

.t2s3 {
    font-size: 14px;
    line-height: 17px;
    color: #666666;
    padding-top: 50px;
}

.t2s4 {
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    padding-top: 50px;
    color: #ffffff;
}

.t2s5 {
    font-size: 18px;
    line-height: 22px;
    color: #1A1C18;
    background: #F0E947;
    padding: 20px;
    margin-top: 40px;
}

.t2s6 {
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    padding-top: 40px;
    margin-left: 0px;
}

.t2s7 li,
.product-wrap li {
    font-size: 18px;
    padding-top: 12px;
    /*opacity: .4;*/
    list-style: none;
    /*margin-left: 50px;*/
    padding-left: 0;
}

.product-wrap li {
    padding-top: 9px;
    padding-bottom: 9px;
}

.t2s7 li:before,
.product-wrap li:before {
    width: 28px;
    height: 3px;
    background: #F0E947;
    display: block;
    content: '';
    position: relative;
    left: -40px;
    top: 12px;
}

.product-wrap hr {
    height: 3px;
    background: linear-gradient(90deg, #F0E947 0%, #24A947 100%);
    margin-left: -15px;
    margin-right: -15px;
}

.product-wrap .col-12,
.product-wrap .col-lg-11 {
    padding: 0;
}

.t2s8 {
    text-align: center;
    margin-top: 20px;
    /*display: grid;*/
}

.hex-xs {
    height: 200px;
    font-size: 12px;
    text-align: center;
}

.hex-xs-1 {
    background: url('../../images/t2s8i1.webp') no-repeat center 0;
}

.hex-xs-2 {
    background: url('../../images/t2s8i3.webp') no-repeat center 0;
}

.hex-xs-3 {
    background: url('../../images/t2s8i2.webp') no-repeat center 0;
}




/* 3 catalog */
.t3s2 {
    width: 428px;
    height: 40px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.cat-item {
    background: radial-gradient(69% 57% at 50% 53%,
            rgba(96, 180, 66, .6) 0,
            rgba(26, 28, 24, 0) 65%);
    width: auto;
    /*height: 400px;*/
    /*display: table-cell;*/
    vertical-align: bottom;
    text-align: center;
    font-size: 16px;
    background-blend-mode: lighten;
}

.cat-item img {
    max-width: 100%;
    height: 305px;
    width: 100%;
    object-fit: contain;
}


.video-owl-sync__video {
    position: relative;
    display: block;
}

.video-owl-sync__video .overflow {
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    top: 2px;
    left: 2px;
    background-color: rgba(0, 0, 0, .3);
}

.video-owl-sync__video:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(240, 233, 71, 1) 0%, rgba(36, 169, 71, 1) 100%);
    background-size: 300% 300%;
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
    -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
}

.video-owl-sync__video:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iODQiIGhlaWdodD0iODQiIHZpZXdCb3g9IjAgMCA4NCA4NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQxLjY2NjcgMC40MjY3NThDMTguNjY2NyAwLjQyNjc1OCAwIDE5LjA5MzQgMCA0Mi4wOTM0QzAgNjUuMDkzNCAxOC42NjY3IDgzLjc2MDEgNDEuNjY2NyA4My43NjAxQzY0LjY2NjcgODMuNzYwMSA4My4zMzMzIDY1LjA5MzQgODMuMzMzMyA0Mi4wOTM0QzgzLjMzMzMgMTkuMDkzNCA2NC42NjY3IDAuNDI2NzU4IDQxLjY2NjcgMC40MjY3NThaTTMzLjMzMzMgNjAuODQzNFYyMy4zNDM0TDU4LjMzMzMgNDIuMDkzNEwzMy4zMzMzIDYwLjg0MzRaIiBmaWxsPSIjRjBFOTQ3Ii8+Cjwvc3ZnPgo=');
    background-size: contain;
    background-repeat: no-repeat;
    height: 83px;
    width: 83px;
}

.video-owl-sync_1 .owl-nav {
    position: absolute;
    bottom: -50px;
    font-size: 70px;
    width: 100%;
}

.owl-next:focus,
.owl-prev:focus {
    outline: none;
}

.owl-prev {
    left: 0;
    position: absolute;
}

.owl-next {
    right: 0;
    position: absolute;
}

.mfp-iframe-scaler iframe video {
    width: 100%;
}

.video-owl-sync_2 .owl-item {
    opacity: .5;
}

.video-owl-sync_2 .owl-item.current {
    opacity: 1;
}

.video-owl-sync_2 {
    max-width: 785px;
}

.video-owl-sync_2 .video-owl-sync__video {
    max-width: 245px;
    width: 100%;
    margin: 0 10px;
}

.video-owl-sync_2 .video-owl-sync__video:after {
    display: none;
}

.video-owl-sync__video img {
    max-width: 100%;
}



/*.cat-item img {border: 1px solid palevioletred}*/
/* 4 tech */
.f4 h3 {
    padding-top: 100px;
    padding-bottom: 40px;
}

.f4 {
    padding-bottom: 50px;
}

.tec {
    width: 100%;
    height: 100%;
    font-size: 24px;
    line-height: 29px;
    position: relative;
}

.tec h3 {
    padding-top: 40px;
}

.tec p:nth-child(1) {
    position: absolute;
    top: 25%;
    left: 30px;
    z-index: 1;
    font-size: 16px;
}

.tec p:nth-child(2) {
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 1;
}

.tec p:nth-child(2) a {
    font-size: 14px;
}



.tec img {
    width: 100%;
    display: block;
}



.f4 {
    position: relative;
    background: radial-gradient(50.23% 47.04% at 42.71% 54.42%, #00713D 0%, #131412 98.13%);
    overflow: hidden;
}

.gradient-f4 {
    background: url("../../images/b4l1.webp") no-repeat;
    background-size: contain;
    display: block;
    height: 100%;
    opacity: .3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.f4 .dark-matte {
    background-color: #131412;
    /*background: #7e9468;*/
    /* border: 1px solid blue; */
    position: absolute;
    width: 100vw;
}

.tech {
    /*background: darkgreen;*/
    margin-bottom: 30px;
    /*background: url("../../images/tech-back-a.webp") no-repeat center 40px;*/
    background-size: cover;
    position: relative;
}

.tech .back-layer-1 {
    display: block;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-size: cover !important;
    z-index: 1;
    position: absolute;
    /*border: 1px solid red;*/
}

.tech-1 .back-layer-1 {
    background: url("../../images/f4-collapse-1-back-layer-0.webp") no-repeat right 50px;
}

.tech-2 .back-layer-1 {
    background: url("../../images/f4-collapse-2-back-layer-0.webp") no-repeat right 50px;
}

.tech-3 .back-layer-1 {
    background: url("../../images/f4-collapse-3-back-layer-0.webp") no-repeat right 50px;
}

.tech-4 .back-layer-1 {
    background: url("../../images/f4-collapse-4-back-layer-0.webp") no-repeat right 50px;
}

.tech .container {
    z-index: 3;
    position: inherit
}

.f4-arrow-collapse {
    opacity: .8;
}

.tech .footnote {
    position: absolute;
    bottom: 25px;
    font-size: 12px;
}

.tech h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #F0E947;
}



.tech p {
    padding: 15px 0px 10px;
}

.tech img {
    display: block;
    margin-top: 20px;
}

.tech-4-lg-image {
    position: relative;
    top: 18px;
    left: 30px;
}

.tech .tech-yellow-block {
    background: #F0E947;
    color: #131412;
    letter-spacing: -0.005em;
    line-height: 21px;
    font-weight: 600;
    font-size: 18px;
    margin-top: 50px;
    margin-bottom: 40px;
    padding: 15px 15px;
}



.tech-yellow-block-4 {
    margin-top: 23px !important;
    margin-bottom: 68px !important;
}





/*fluid wrappers*/
.f1-slide1-back {

    background: url('../../images/f1-slide1-back.webp') no-repeat center bottom;
}

.f1 .carousel-item.slide-2 {

    background: url('../../images/f1-slide2-nodes.webp') no-repeat 0 0;
    background-size: cover;

}

.f1 .carousel-item.slide-3 {

    background: url('../../images/f1-slide3-drops.webp') no-repeat 0 0;
    background-size: cover;

}

.f1 {
    padding: 0;
    background: url("../../images/background-b1.webp") no-repeat center 0;
    background-size: cover;
    background-position-y: bottom;
}

.f1 .carousel-item {
    height: 900px;
}

.f1 .carousel-caption .slide-number .current {
    font-size: 24px;
}

/*.f1-slide1-letuch {*/
/*    background: url("../../images/f1-slide1-letuch.webp") no-repeat 80% bottom;*/
/*}*/
.f1-slide2-letuch {
    background: url("../../images/f1-slide2-letuch.webp") no-repeat 100% bottom;
}

.f1-slide3-volosogon {
    background: url("../../images/f1-slide3-volosogon.webp") no-repeat center bottom;
    background-position-y: 80%;
    background-position-x: 55%;
    background-size: 660px;
}

.f1-slide3-sanfayans {
    background: url("../../images/f1-slide3-sanfayans.webp") no-repeat center bottom;
    background-position-y: 80%;
    background-position-x: 55%;
    background-size: 660px;
}

.volosogon-technology p {
    max-width: 88%;
}

.volosogon-technology h5 {
    margin-bottom: 35px;
}

.gel-universal-technology ul li {
    padding: 0;
    font-size: 16px;
}

.f1-slide3-9 {
    background: url('../../images/f1-slide-3-lines.webp') no-repeat 20px 180px;
    height: 900px;
}

.f1-slide3-circle-1,
.f1-slide3-circle-2,
.f1-slide3-circle-3,
.f1-slide3-circle-4,
.f1-slide3-circle-5 {
    display: block;
    height: 85px;
    padding-left: 85px;
    margin-left: 100px;
    position: relative;
}

.f1-slide3-circle-1 {
    background: url('../../images/f1-slide3-circle-1.webp') no-repeat 0 0;
    top: 245px;
}

.f1-slide3-circle-2 {
    background: url('../../images/f1-slide3-circle-2.webp') no-repeat 0 0;
    top: 290px;
}

.f1-slide3-circle-3 {
    background: url('../../images/f1-slide3-circle-3.webp') no-repeat 0 0;
    top: 215px;
}

.f1-slide3-circle-4 {
    background: url('../../images/f1-slide3-circle-4.webp') no-repeat 0 0;
    top: 245px;
}

.f1-slide3-circle-5 {
    background: url('../../images/f1-slide3-circle-5.webp') no-repeat 0 0;
    top: 265px;
}

.f1-slide33-9 {
    background: url("../../images/f1-slide-3-3-lines.webp") no-repeat 20px 180px;
    height: 900px;
}

.f1-slide3-circle-1-xs,
.f1-slide3-circle-2-xs,
.f1-slide3-circle-3-xs,
.f1-slide3-circle-4-xs,
.f1-slide3-circle-5-xs {
    display: block;
    height: 85px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    text-transform: initial;
    padding: 10px 0px 0 60px;
}

.f1-slide3-circle-1-xs {
    background: url('../../images/f1-slide3-circle-1-xs.webp') no-repeat -10px 0;
    margin: 50px 0 0 0;
}

.f1-slide3-circle-2-xs {
    background: url('../../images/f1-slide3-circle-2-xs.webp') no-repeat -10px 0;
}

.f1-slide3-circle-3-xs {
    background: url('../../images/f1-slide3-circle-3.webp');
    background-size: 44px;
    background-repeat: no-repeat;
    height: auto;
    margin-bottom: 15px;
}

.f1-slide3-circle-4-xs {
    background: url('../../images/f1-slide3-circle-4.webp');
    background-size: 44px;
    background-repeat: no-repeat;
    height: auto;
    margin-bottom: 15px;
}

.f1-slide3-circle-5-xs {
    background: url('../../images/f1-slide3-circle-5.webp');
    background-size: 44px;
    background-repeat: no-repeat;
    height: auto;
    margin-bottom: 15px;
}


.f1-slide3-paragraph {
    border: 1px solid #24A947;
}

.f2 {
    position: relative
}

.f2 ul {
    padding-top: 15px;
}

.f3 {
    background: url("../../images/b3l1.webp") no-repeat center 0;
    background-size: cover;
    /*overflow-x: scroll;*/
}



.f5 {
    background: url("../../images/b5-nodes.webp") no-repeat center bottom;
    background-size: cover;
    min-height: 650px;
}

.b5 {
    position: relative;
}

.f5-letuch {
    background: url("../../images/b5-letuch.webp") no-repeat 80% bottom;
}



.f6 {

    background: radial-gradient(38.23% 46.04% at 48.71% 54.42%, rgba(0, 113, 61, .7) 0, rgba(19, 20, 18, .7) 98.13%);
}

.f7 {
    height: 491px;
    background-image: url("../../images/map-dummy2560.webp");
    background-position: center 0;
    background-repeat: no-repeat;
    padding: 0;
}

.product-overlay {
    /*position: absolute;*/
    left: 0;
    width: 100%;
    /*border: 1px solid red;*/
    z-index: 10;
    background: url("../../images/f9back.webp") no-repeat center 0;
    background-size: cover;

}

.volume-ml {
    font-size: 18px;
    line-height: 22px;
}

.product-wrap {
    width: 100%;
    /*border: 1px dotted blue;*/
}

.product-wrap h3 {
    text-align: left;
    color: #ffffff;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
}

.h3-slave {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
}

.product-wrap .info {
    background: rgba(37, 173, 73, 0.37);
}

.product-wrap .info p {
    padding-bottom: 15px;
}

.product-wrap .info .product-info-pre-hl {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    padding-top: 10px;
}

.product-wrap .info .product-info-hl {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: #F0E947;
    /*padding-top: 10px;*/
}

.product-wrap .make-sure {
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

.product-wrap .buy-now-header {
    text-align: left;
    text-transform: uppercase;
    width: 100%;
}

.footnote {
    font-size: 14px;
    line-height: 17px;
    color: #898989;
    padding: .5em 0;
}

.product-fig-caption {
    font-size: 12px;
    line-height: 15px;
}



/* The Overlay (background) */
.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1029;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
    background: url("../../images/f9back.webp") no-repeat center 0;
    background-size: cover;

}

.overlay-content {
    position: relative;
    top: 100px;
    width: 100%;
    text-align: center;
}

.overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 30px;
    display: block;
    transition: 0.3s;
    text-transform: uppercase;
    line-height: 1em;
}

.overlay a:hover,
.overlay a:focus {
    color: #f1f1f1;
}

.overlay .closebtn {
    position: absolute;
    /*top: 20px;*/
    /*right: 45px;*/
    font-size: 60px;
}

.closebtn {
    display: none;
}

#navbar {
    transition: .4s;
}

#navbar a {
    font-size: 14px;
    transition: .2s ease-in-out;
}

#navbar a:hover {
    color: #F0E947;
}

nav {
    background: rgba(26, 28, 24, 0.9);
    background: none;
}

nav .active {
    color: #F0E947;
    transition: .5s;
}



.b5-logo-expel-s .b5-logo {
    margin-top: 25px;
}

.b5-logo-expel-s .b5-logo img {
    width: 66px;
    position: relative;
    left: 14px;
}

.b5-logo-clean-s img {
    width: 91px;
}

ymaps {
    filter: grayscale(100%) !important;
    /*backdrop-filter:invert(100%) grayscale(0%)hue-rotate(180deg) !important;*/
}


.f1 .owl-carousel,
.f1 .owl-slide,
.f1 .owl-slide-1,
.f1 .owl-slide-2,
.f1 .owl-slide-3,
.f1 .owl-slide-1-inner,
.f1 .owl-slide-2-inner,
.f1 .owl-slide-3-inner,
.f1 .owl-slide .container {
    width: 100vw;
    height: 100vh;
    min-width: 100vw;
    min-height: 100vh;
    height: 568px;
    min-height: 568px;
}

.f1 .owl-carousel .owl-slide {
    width: 100%;
    /*border: 1px solid #1ccacd;*/
    background: url("../../images/background-b1.webp") no-repeat center 0;
    background-size: cover;
}

.f1 .owl-slide-1 {
    background-position-x: 20%;
}

.f1 .owl-slide-2 {
    background-position-x: 50%;
}

.f1 .owl-slide-3 {
    background-position-x: 100%;
}

.f1 .owl-slide-1 .owl-slide-1-inner {
    /*background: url('../../images/owl-slide1-letuch.webp') no-repeat 100% 100%;*/
    padding: 0;
    min-height: 568px;
}

.f1 .owl-slide-header-1 {
    padding-top: 100px;
    text-transform: uppercase;
}

.f1 .owl-slide-1-t2 {
    color: #F0E947;
    text-transform: uppercase;
    padding-top: 10px;
}

.f1 .owl-slide-1-t2-s1 {
    font-size: 32px;
}

.f1 .owl-slide-1-t2-s2 {
    font-size: 23px;
    line-height: 28px;
    letter-spacing: 0.01em;
}

.f1 .owl-slide-1-t2-s3 {
    font-size: 14px;
    line-height: 150%;
}

.f1 .owl-slide-1-t3 {
    /*padding: 1em;*/
    margin-top: 10px;
}

.f1 .owl-slide-1-bottom {
    height: 337px;
}

.f1 .owl-slide-2-t2 {
    font-weight: 600;
    font-size: 38px;
    line-height: 46px;
    text-transform: uppercase;
    color: #F0E947;
    margin-top: 10px;
}

.f1 .owl-slide-1-t3 {
    color: #F0E947;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 25px;
}

.f1 .owl-slide-1-t3 span {
    font-size: 16px;
}

.f1 .owl-slide-2 .owl-slide-2-inner {
    /*background: url('../../images/owl-slide2-letuch.webp') no-repeat 40px 180px;*/
    background-size: auto;
}

.f1-controls {
    /*border: 1px solid aqua; background: #1ccacd;*/
    width: 400px;
    height: 50px;
    position: absolute;
    z-index: 1;
    top: 800px;
    bottom: 0px;
    left: 45px;
    right: 0px;
}

.f1 ol.carousel-indicators {
    right: unset;
    top: 20px;
    left: 0px;
}

.f1 ol.carousel-indicators li {
    height: 2px;
    margin: 0;
    position: absolute;
    left: 0;
    background-color: #747474;
    opacity: 1;
}

.f1 ol.carousel-indicators li.active {
    background-color: #F0E947;
    z-index: 1;
    transition: .5s;
}

.f1 ol.carousel-indicators li.active p {
    position: absolute;
    width: 80px;
    height: 40px;
    /*border: 1px solid thistle;*/
    color: white;
    text-indent: initial;
    font-size: 24px;
    left: -90px;
    top: -10px;
}

.f1 ol.carousel-indicators li.active p span {
    font-size: 16px;
}

.f1 ol.carousel-indicators li:nth-child(1) {
    width: calc(159px / 4 * 1);
}

.f1 ol.carousel-indicators li:nth-child(2) {
    width: calc(159px / 4 * 2);
}

.f1 ol.carousel-indicators li:nth-child(3) {
    width: calc(159px / 4 * 3);
}

.f1 ol.carousel-indicators li:nth-child(4) {
    width: calc(159px / 4 * 4);
}

.f1 a.carousel-control-next,
.f1 a.carousel-control-prev {
    opacity: 1;
    /*border: 1px solid red;*/
    width: 13px;
    height: 18px;
    padding: 20px;
    top: 0px;
    right: 80px;
}

.f1 a.carousel-control-prev {
    right: 120px;
    left: unset;
}

.f1 span.carousel-control-prev-icon,
.f1 span.carousel-control-next-icon {
    width: 13px;
    height: 18px;
    color: #1ccacd;
}

.f1 span.carousel-control-prev-icon {
    background: url("data:image/svg+xml;utf8,<svg width='13' height='18' viewBox='0 0 13 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M12 1L2 9L12 17' stroke='white' stroke-width='2'/></svg>") no-repeat;
}

.f1 span.carousel-control-next-icon {
    background: url("data:image/svg+xml;utf8,<svg width='13' height='18' viewBox='0 0 13 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L11 9L1 17' stroke='white' stroke-width='2'/></svg>") no-repeat;
}


.f1 .owl-dots {
    position: absolute;
    top: 530px;
    left: 80px;
    width: 150px;
}

.f1 .owl-dots button {
    height: 3px;
    margin: 0;
    position: absolute;
    left: 0;
    background-color: #747474;
    opacity: 1;
}

.f1 .owl-dots button.active {
    background-color: #F0E947;
    z-index: 2;
}

.f1 .owl-dots button:nth-child(1) {
    width: 25px;
}

.f1 .owl-dots button:nth-child(2) {
    width: 50px;
}

.f1 .owl-dots button:nth-child(3) {
    width: 75px;
}

#f1_owl_counter {
    position: absolute;
    left: 15px;
    top: 520px;
    z-index: 3;
    font-size: 18px;
}

#f1_owl_counter span {
    font-size: 14px;
}

.f2 .about-more-hide-btn {
    display: none;
}


.f6 .retailerItem {
    /* display: table-cell; */
    /* vertical-align: middle; */
    height: 140px;
    min-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*width: auto;*/
    padding: 10px 20px;
    /*border: 1px solid;*/
    /*transform: matrix(.8,0,0,.8,0,0);*/
}

.owl-dots-products-xs-1,
.owl-dots-products-xs-2,
.owl-dots-products-xs-3 {
    width: 100%;
    height: 20px;
    text-align: center;
}

.owl-dots-products-xs-1 .owl-dot,
.owl-dots-products-xs-2 .owl-dot,
.owl-dots-products-xs-3 .owl-dot {
    display: inline-block;
    zoom: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    background: none;
    border-style: solid;
    border-width: 0;
}

.owl-dots-products-xs-1 .owl-dot:nth-child(3),
.owl-dots-products-xs-2 .owl-dot:nth-child(3),
.owl-dots-products-xs-3 .owl-dot:nth-child(3) {
    display: none;
}

.owl-dots-products-xs-1 .owl-dot span,
.owl-dots-products-xs-2 .owl-dot span,
.owl-dots-products-xs-3 .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #D6D6D6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px;
}

.owl-dots-products-xs-1 .owl-dot.active span,
.owl-dots-products-xs-2 .owl-dot.active span,
.owl-dots-products-xs-3 .owl-dot.active span {
    background: #ffff66;
}


.f3 .owl-products-row .cat-item {
    /*border: 1px solid #1ccacd;*/
    /*height: 400px;*/
    width: 200px;
    min-width: 200px;
    display: block;
}

.f3 .owl-products-row .cat-item img {
    display: inline;
    object-fit: contain;
}

.product-image img {
    max-width: 100%;
}



.container-fluid.product .product-wrap h4 {
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    color: #F0E947;
}

button.animated-box {
    color: #ffffff;
    font-size: 16px;
    text-transform: uppercase;
    padding: 8px 20px;
}

.modal {

    background: rgba(67, 67, 67, 0.6);
}

.modal-body {
    background-color: #121413;
    padding-top: 0;
    padding-bottom: 0;
}

.modal .close {
    opacity: 1;
}

.modal .product-overlay {
    padding: 30px;
    background: rgb(10, 55, 34) url("../../images/modal-product-overlay-back-3.webp") no-repeat left top;
    background-size: initial;
}



.modal .product-overlay .product-image {
    width: 100%;
    background: radial-gradient(50.83% 50.51% at 50% 51.08%, #60B442 0%, rgba(26, 28, 24, 0) 100%);
}

.modal .product-overlay .product-image img {
    height: 204px;
}

.modal .product-overlay h3,
.modal .product-overlay h3 .h3-slave {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding-top: 20px;
}

.modal .product-overlay h3 .volume-ml {
    font-size: 14px;
    line-height: 17px;
}

.modal .product-overlay h4 {
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #F0E947;
}

.modal .product-overlay ul li {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
}

.modal .product-overlay .info p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.modal .product-info-hl {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #F0E947;
    /* padding-top: 10px; */
}

.modal .product-overlay .buy-now-header {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: initial;
    margin-bottom: 20px;
}

.modal .product-overlay .buy-now a.retailerItem {
    display: inline-block;
    vertical-align: middle;
    height: 60px;
    /*min-height: 80px;*/
}

.modal .product-overlay .buy-now a.retailerItem img {
    transform: scale(0.7);
    display: block;
}

.modal .product-image.text-center.mt-5 {
    margin-top: 0 !important;
}

.modal-dialog {
    max-width: unset;
    margin: 0 0 0 0;
}

.modal-content {
    max-width: 100%;
    width: 100%;
    background: rgba(67, 67, 67, 0.6);
    border: none;
}

.modal-header {
    border-bottom: none;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 2080;
    height: 60px;
    background: rgba(67, 67, 67, 0.6);
}

.modal .buy-now.m-3 {
    margin-top: 0 !important;
}

.f3 .cat-item p {
    height: 80px;
    /* height: 100%; */
    font-size: 14px;
}

.slider-video:before {
    content: "";
    width: 50px;
    z-index: 2;
    height: 568px;
    transform: translate(-50%, 0%);
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    left: 50%;
    background-image: url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iODQiIGhlaWdodD0iODQiIHZpZXdCb3g9IjAgMCA4NCA4NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQxLjY2NjcgMC40MjY3NThDMTguNjY2NyAwLjQyNjc1OCAwIDE5LjA5MzQgMCA0Mi4wOTM0QzAgNjUuMDkzNCAxOC42NjY3IDgzLjc2MDEgNDEuNjY2NyA4My43NjAxQzY0LjY2NjcgODMuNzYwMSA4My4zMzMzIDY1LjA5MzQgODMuMzMzMyA0Mi4wOTM0QzgzLjMzMzMgMTkuMDkzNCA2NC42NjY3IDAuNDI2NzU4IDQxLjY2NjcgMC40MjY3NThaTTMzLjMzMzMgNjAuODQzNFYyMy4zNDM0TDU4LjMzMzMgNDIuMDkzNEwzMy4zMzMzIDYwLjg0MzRaIiBmaWxsPSIjRjBFOTQ3Ii8+Cjwvc3ZnPgo=');
}




#product1p {
    visibility: hidden;
}

/* ████████████████████████████████████████████████████████ */

.product1 .buy-now-header {
    text-align: center
}



/*    ,--. ,--.,--.    ,--. ,--.,--.,--------.    */
/*    |  | |  ||  |    |  .'   /|  |'--.  .--'    */
/*    |  | |  ||  |    |  .   ' |  |   |  |       */
/*    '  '-'  '|  |    |  |\   \|  |   |  |       */
/*     `-----' `--'    `--' '--'`--'   `--'       */

.animated-box {
    font-family: 'Montserrat', Arial, sans-serif;
    position: relative;
}

a.animated-box,
span.animated-box {
    margin: 0px;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    line-height: normal;
    border-radius: unset;
}

span.animated-box:hover {
    color: #fff;
    cursor: initial;
}

// .btn:hover,
a.animated-box:hover,
a.animated-box:active,
a.animated-box:focus,
.active-btn {
    background: linear-gradient(to right, rgba(240, 233, 71, 1) 0%, rgba(36, 169, 71, 1) 100%);
    animation: hover-color-link .5s ease-in-out;
    color: #000000;
}

.animated-box:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(240, 233, 71, 1) 0%, rgba(36, 169, 71, 1) 100%);
    background-size: 300% 300%;
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
    -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
}

.animated-box.in:after {
    animation: frame-enter 1s forwards ease-in-out reverse, gradient-animation 2s ease-in-out infinite;
}

@keyframes gradient-animation {
    0% {
        background-position: 15% 0%;
    }

    20% {
        background-position: 85% 100%;
    }

    100% {
        background-position: 15% 0%;
    }
}

@keyframes frame-enter {
    0% {
        clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
    }

    30% {
        clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) 100%, 100% 100%, 100% 0%, 0% 0%);
    }

    50% {
        clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, 100% 0%, 0% 0%);
    }

    80% {
        clip-path: polygon(0% 100%, 3px 100%, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 0%, 0% 0%);
    }

    100% {
        clip-path: polygon(0% 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 0% 100%);
    }

    0% {
        -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
    }

    30% {
        -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) 100%, 100% 100%, 100% 0%, 0% 0%);
    }

    50% {
        -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, 100% 0%, 0% 0%);
    }

    80% {
        -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 0%, 0% 0%);
    }

    100% {
        -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 0% 100%);
    }
}

@keyframes hover-color-link {
    0% {
        color: #ffffff;
    }

    100% {
        color: #000000;
    }
}


.btn-display-none {
    display: none !important;
}








/* ████████████████████████████████████████████████████████ */





/*

        ▓█████▄ ▓█████ ██▒   █▓
        ▒██▀ ██▌▓█   ▀▓██░   █▒
        ░██   █▌▒███   ▓██  █▒░
        ░▓█▄   ▌▒▓█  ▄  ▒██ █░░
        ░▒████▓ ░▒████▒  ▒▀█░

 */

/*.ds-border {*/
/*    border: 1px solid red;*/
/*}*/

/*main    {border: 1px solid yellow;         }*/
/*.b1     {border: 1px dotted turquoise;      }*/
/*.b2     {border: 1px dotted mediumpurple;   }*/
/*.b3     {border: 1px dotted deeppink;       }*/
/*.b4     {border: 1px dotted darkslateblue;  }*/
/*.b5     {border: 1px dotted royalblue;      }*/
/*.b6     {border: 1px dotted khaki;          }*/
/*.b7     {border: 1px dotted bisque;         }*/
/*.b8     {border: 1px dotted azure;          }*/

/*div,main,.b1,.b2,.b3,.b4,.b5,.b6,.b7,.b8 {border: none;}*/

/*grid overlay*/
.design-overlay {
    position: absolute;
    top: 0;
    left: 0px;
    /*left:20px;*/
    /*width:1440px;*/
    width: 100%;
    height: 6928px;
    margin: 0 auto;
    /*background: #ffffff url("../../images/design-overlay.webp") no-repeat center 0;*/
    background: #ffff66;
    text-align: center;
    opacity: .2;
}

.design-overlay img {
    opacity: .5
}

/*grid overlay*/
.dev-grid-overlay {
    position: fixed;
    top: 0vh;
    width: 100%;
    z-index: 99;
    /*display: none;*/
}

.dev-grid-overlay .container .row .col {
    background: rgba(250, 200, 230, .2);
    border-right: 1px solid rgba(200, 200, 200, .2);
    height: 30vh;
}

.dev-grid-overlay .container .row .col:nth-child(1) {
    border-left: 1px solid rgba(200, 200, 200, .2);
}

.dev-grid-overlay .container .row .col:nth-child(odd) {
    background: rgba(200, 200, 230, .2);
}

.product h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
}

.owl-slide1-letuch-x3 {
    position: absolute;
    display: block;
    width: 164px;
    height: 337px;
    right: -15px;
    bottom: 0;
}

.owl-slide1-letuch-x3 img {
    width: 164px !important;
    height: 337px !important;
}

.owl-slide1-productx3 {
    position: absolute;
    display: block;
    width: 477px;
    height: 309px;
    left: -102px;
    bottom: 37px;
}

/* .owl-slide1-productx3 img {
    width: 350px !important;
} */

.owl-slide2-letuch-x3 {
    position: absolute;
    width: 164px;
    height: 337px;
    display: block;
    left: 109px;
    bottom: -18px;

}

.owl-slide2-letuch-x3 img {
    width: 220px !important;
}

.owl-slide3-back-x3 {
    position: absolute;
    display: block;
    width: 405px;
    height: 504px;
    left: 61px;
    bottom: -15px;
}

.owl-slide3-back-x3 img {
    width: 405px;
    height: 504px;
}

.size-overlay {
    position: fixed;
    z-index: 10010 !important;
    top: 0;
    left: 50vw;
    padding: 5px 20px;
    background: rgba(255, 255, 255, .8);
    color: #1A1C18;
    z-index: 101;
    box-shadow: 0px 5px 5px 0px black;
    border-radius: 0 0 5px 5px;
}

.contacts__phone {
    line-height: 24px;
}

.white-popup-block {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1440px;
    margin: 0 auto;
}

.mfp-close-btn-in .mfp-close {
    color: #fff;
}

.white-popup-block video {
    max-width: 100%;
}

.contact {
    margin-top: 150px;
    position: relative;
}

.contact:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -40px;
    width: 100%;
    height: 750px;
    /* z-index: 0; */
    background-image: url("../../images/contact-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    opacity: .5;
}

.contact-header {
    padding-top: 0;
    text-align: left;
    margin-bottom: 50px;
}

.contact__image {
    margin-bottom: 25px;
}

.contact__content {
    max-width: 460px;
    position: relative;
}

.contact__content--bordered {
    padding-bottom: 35px;
}

.contact__content--bordered:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background: linear-gradient(90deg, #F0E947 0%, #24A947 100%);
}

.contact__upeco-logo {
    margin-top: 35px;
}

.contacts__address {
    margin-bottom: 25px;
}

.contact div>ymaps {
    height: 540px !important;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 0;
    background-color: #10251A;
    color: C5C5C5;
    font-size: 12px;
    margin-top: 40px;
}

.contact__upeco-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
}

.contact__upeco-group a {
    text-transform: none;
}

.product-close {
    position: absolute;
    right: 0;
    top: 50px;
    cursor: pointer;
}

.tech-close {
    position: absolute;
    right: 100px;
    top: 100px;
    z-index: 2;
    cursor: pointer;
}

.carousel-control-next,
.carousel-control-prev {
    width: auto;
}

.carousel-control {
    position: absolute;
    width: 100%;
    top: 50%;
    max-width: 790px;
    left: 50%;
    transform: translateX(-50%);
}

.carousel-control button {
    background: transparent;
    border: transparent;
}

#carouselOnlineRetailers,
#carouselOfflineRetailers {
    position: relative;
}

#carouselOnlineRetailersControls,
#carouselOfflineRetailersControls {
    max-width: 650px;
    margin: 0 auto;
}

.gif-static {
    display: none !important;
}

.gif-static:hover {
    opacity: 0;
    z-index: 2;
}

.gif-static:hover~.gif-active {
    opacity: 1;
}

.gif-active {
    position: relative;
    top: 0;
    left: 0;
    max-width: 100%;
    width: calc(100% - 15px);
    padding-left: 15px;
    opacity: 1;
    z-index: -1;
}

.map-wrapper {
    position: relative;
}

.map-wrapper iframe {
    z-index: 2;
    position: relative;
}

.map-wrapper:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    right: 0;
    bottom: 0;
    width: 101.5%;
    height: 101.5%;
    z-index: 1;
    background: linear-gradient(90deg, #F0E947 0%, #24A947 100%);
}



.nav-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #fff;
    padding: 3px 8px !important;
    /* background: linear-gradient(90deg, #F0E947 0%, #24A947 100%); */
    position: relative;
    transition: .3s ease-in-out;
}

.nav-btn:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(240, 233, 71, 1) 0%, rgba(36, 169, 71, 1) 100%);
    background-size: 300% 300%;
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
    -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
}

.nav-btn:hover {
    background: linear-gradient(90deg, #F0E947 0%, #24A947 100%);
    color: #000704 !important;
}





/* 
.tech-x-btn {
    display: none !important;
}
.tec p:nth-child(1) {
    top: 40%;
}
*/