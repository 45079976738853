.navigation {
    z-index: 11;
    position: fixed;
    width: 100%;
    transition: .3s ease-in-out;

    &--scrolled {
        background-color: rgba(26, 28, 24, 0.9);

        .navigation__inner {
            padding: 15px 0;
        }

        .navigation__logo {
            img {
                width: 30%;
            }
        }
    }

    &__desktop {
        padding: 30px 0;
        display: flex;
        justify-content: space-between;
        position: relative;
        transition: .3s ease-in-out;
    }

    &-mobile {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        transition: .3s ease-in-out;

        &__menu {
            &-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 15px;

                img {
                    &:nth-child(1) {
                        max-width: 3em;
                    }

                    &:nth-child(2) {
                        max-width: 50px;
                    }
                }
            }
        }
    }

    // &__hamburger {
    //     width: 20px;
    //     height: 20px;
    //     position: relative;
    //     cursor: pointer;

    //     span {
    //         display: block;
    //         width: 7px;
    //         height: 7px;
    //         background-color: #F0E947;
    //         border-radius: 20px;
    //         position: absolute;

    //         &:nth-child(1) {
    //             top: 0;
    //             left: 0;
    //         }

    //         &:nth-child(2) {
    //             top: 0;
    //             right: 0;
    //         }

    //         &:nth-child(3) {
    //             bottom: 0;
    //             left: 0;
    //         }

    //         &:nth-child(4) {
    //             bottom: 0;
    //             right: 0;
    //         }
    //     }
    // }

    &__logo {
        flex-grow: 2;

        img {
            transition: .3s ease-in-out;
            max-width: fit-content;
            width: 100%;
        }
    }

    &__menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 4;
    }

    &__item {
        position: relative;
        font-size: 14px !important;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
            color: #E8C511;

            &:has(.navigation__dropdown){
                &::before{
                    opacity: 1;
                }
            }
        } 

        .btn--accent,
        .btn--secondary {
            padding: 3px 8px;
            font-size: 14px;
        }

        &::before {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 10px solid #F0E947;
            opacity: 0;
        }
    }

    &__dropdown {
        display: none;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: max-content;
        top: 100%;
        left: 0;
        opacity: 0;
        padding-top: 20px;

        &--large{
            left: -19em;
        }

        &-item {
            padding: 10px 30px;
            background: #F0E947;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #333333 !important;
            position: relative;
            text-transform: none;

            &::before,
            &::after {
                content: "";
                width: 1px;
                height: 100%;
                position: absolute;
                top: 0;
            }

            &:not(:first-child)::before {
                background-color: #E8C511;
                left: 0;
            }

            &:not(:last-child)::after {
                background-color: #F7F6AF;
                right: 0;
            }

            &:hover {
                // background-color: #E8C511;
                background: linear-gradient(90deg, #F0E947 0%, #24A947 100%);
                text-transform: none;
            }
        }
    }
}


@media(max-width: 1199px){
    .navigation{
        &__dropdown{
            &--large{
                width: calc(100vw - 19em);
                left: -20em;
                .navigation__dropdown-item{
                    font-size: 12px;
                }
            }
        }
    }
}

@media(max-width: 991px) {
    .navigation {
        background-color: #131412;
        top: 0;

        &__logo {
            img {
                width: 3em;
            }
        }

        &__menu {
            position: fixed;
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            flex-direction: column;
            background-image: url("/images/product-content-bg.webp");
            overflow: auto;
            gap: 30px;
            padding-bottom: 30px;
            transform: translateX(-100%);

            .btn--accent {
                background: linear-gradient(90deg, #F0E947 0%, #24A947 100%);
                color: #333333;
                text-align: center;
                font-size: 24px;
                font-weight: 500;
                line-height: 29px;
            }
            .btn--secondary {
                background: linear-gradient(90deg, #009BA5 0%, #064178 100%);
                color: white;
                text-align: center;
                font-size: 24px;
                font-weight: 500;
                line-height: 29px;
            }
        }

        &__item {
            text-align: center;
            font-size: 24px !important;
            font-weight: 500;
            line-height: 29px;

            &::before{
                display: none;
            }

            &:has(.navigation__dropdown){
                &::after{
                    content: "";
                    position: absolute;
                    right: -20px;
                    top: 7px;
                    width: 8px;
                    height: 16px;
                    background-image: url("/images/ui-carousel-retailers/b6-carousel-next-hover.webp");
                    background-repeat: no-repeat;
                    background-size: cover;
                    transition: .2s ease-in;
                }

                &:hover{
                    &::after{
                        transform: rotateZ(90deg);
                    }
                }
            }
        }

        &__dropdown {
            position: relative;
            flex-direction: column;
            top: 0px;

            &::before {
                display: none;
            }

            &-item {
                background-color: transparent;
                color: #fff !important;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;

                &:hover{
                    background-color: transparent;
                }

                &::before,
                &::after {
                    display: none;
                }
            }
        }
    }
}