@media (max-width: 1200px) {
    .cat-item {
        font-size: 14px;
    }
}

@media screen and (max-width: 991px) {
    .video-owl-sync_2 .video-owl-sync__video {
        margin: 0 auto 0 0;
        max-width: 90%;
    }
}

@media (max-width: 992px) {
    .nav-btn {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #000704;
        padding: 3px 8px !important;
        background: linear-gradient(90deg, #F0E947 0%, #24A947 100%);
        position: relative;
        transition: .3s ease-in-out;
    }

    .nav-btn:after {
        display: none;
    }

    .nav-btn:hover {
        background: linear-gradient(90deg, #F0E947 0%, #24A947 100%);
        color: #000704 !important;
    }

    .contact__content {
        max-width: none;
    }

    .contact__upeco-group {
        margin-bottom: 35px;
    }

    .contact {
        margin-top: 0px;
    }


    .f5 {
        position: relative;
        overflow: hidden
    }

    .f5 .row {
        padding: 0
    }

    .b5 .row {
        padding: 0
    }

    .b5 .row .col-xl-7,
    .b5 .row .col-lg-7 {
        padding: 0
    }

    .f5 .b5-header-letu {
        font-size: 18px;
        line-height: 136%;
    }

    .f5 .hl-name {
        font-weight: 800;
        font-size: 28px;
        line-height: 141%;
        display: block;
    }

    .f5 .no-more-dirt {
        margin-left: 0;
        margin-top: 15px;
        /*width: 290px;*/
        width: 100%;
        font-size: 16px;
    }

    .f5 .info-frame {
        padding: 0;
    }

    .f5-letuch {
        background: none;
    }

    .b5-logo-w {
        display: none;
    }

    .f5-mobile-bottom {
        width: 100px;
        height: 120px;
        background: url("../../images/f5-mobile-bottom.webp") no-repeat 0 0;
        position: relative;
        top: 11px;
        left: 0px;
    }

    .f3 {
        padding: 0;
        overflow: visible;
    }

    .navbar {
        height: 70px;
        padding: 0rem .5rem;
    }

    .navbar-brand {
        padding-left: .5rem;
    }

    .navbar-brand img {
        width: 44px;
    }

    .f3 h4 {
        padding: 40px 0;
        text-align: center;
    }

    .f3 h4.first-h4 {
        padding: 8px 0 20px 0;
        text-align: center;
    }

    .f3 .cat-item {
        margin-bottom: 20px;
    }

    .f3 .cat-item p {
        margin: 0 0 10px;
        font-size: 14px;
    }

    .f3 .cat-item button {
        font-size: 14px;
        padding: 10px;
    }

    .f4 h3 {
        padding-top: 40px;
    }

    .retailers-header {
        padding-top: 40px;
        padding-bottom: 0px;
    }

    .contact-header {
        padding-top: 20px;
    }

    .container-fluid.product {
        display: none
    }

    .border-line:after {
        height: 2px;
        width: 100%;
        left: 0;
        bottom: -15px;
        right: auto;
        top: auto;

    }

    .tech-pic-4-1 {
        position: relative;
        right: 15px;
    }

    .tech-pic-4-2 {
        position: relative;
        left: 15px;
    }
}

@media screen and (max-width: 768px) {
    .video-owl-sync_2 .owl-stage-outer {
        width: 100vw;
    }
}

@media (max-width: 768px) {

    .h3{
        font-size: 18px;
        line-height: 22px;
    }

    .tech-close {
        right: 10px;
        top: 7px;
        z-index: 9;
    }

    .contact-header {
        font-size: 20px;
        margin-bottom: 0px;
    }

    .house-cleaning-slider.owl-carousel.owl-drag .owl-item {
        height: 356px;
    }

    .container-fluid {
        overflow-x: hidden;
    }

    .f2 {
        overflow: hidden;
    }

    .f1 {
        overflow-x: visible;
    }

    .b2 {
        padding: 0;
    }

    .b2l3 {
        transform: matrix(0.62, -0.78, -0.78, -0.62, 0, 0);
        right: -400px;
        top: 170px;
    }

    .t2s2 {
        padding: 10px 0 20px 0;
    }

    .t2s3 {
        padding-top: 20px;
        padding-bottom: 100px;
    }

    .t2s4 {
        font-size: 20px;
        line-height: 26px;
        width: 200px;
        margin: 0 auto;
    }

    .t2s5 {
        font-size: 14px;
        line-height: 17px;
        padding: 15px;
    }

    .t2s6 {
        width: 300px;
    }

    .t2s7 li {
        font-size: 14px;
    }

    .t2s8 {
        font-size: 12px;
        line-height: 18px;
    }

    .f4 .col-12 {
        padding: 0 15px;
    }

    .f4 p {
        font-size: 16px;
        line-height: 20px;
        left: 21px;
    }

    .f3 .col-12 {
        padding: 0;
    }

    .f3 h4 {
        font-size: 16px;
        line-height: 20px;
    }

    .cat-item {
        /*transform: matrix(.8,0,0,.8,0,0);*/
    }

    .f6 .container,
    .f6 .row {
        padding: 0;
    }

    .f3 h3,
    .f4 h3,
    .f6 h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }

    .f6 .row.mt-5 {
        margin-top: 1.5rem !important;
    }

    .f8 {
        font-size: 12px;
        line-height: 15px;
    }

    .f8 .logo img {
        width: 100px;

    }

    .b8 .row {
        padding: 0
    }

    .b8 .col-md-12 {
        padding-right: 0;
    }
}

@media (max-width: 576px) {
    .infotext-wrapper {
        padding: 0;
    }

    .f8 .logo img {
        width: 80px;
    }

    .f3 .owl-products-row {
        /*overflow-x: scroll;*/
    }
}

@media (max-width: 575px) {
    .f3 .owl-products-row .cat-item {
        padding: 0 10px;
        min-width: auto;
        max-width: 100%;
        height: calc(100% - 20px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }

    .owl-products-row .owl-item {
        max-width: calc(100vw / 2);
    }

    .f3 .cat-item p {
        display: flex;
        align-items: center;
    }

    .owl-products-row .owl-stage {
        display: flex;
        align-items: stretch;
    }



    .no-more-dirt {
        line-height: 21px;
    }

    .owl-slide3-back-x3 {
        bottom: -25px;
    }

    .owl-slide3-back-x3 img {
        height: 600px;
        object-fit: cover;
    }

    .size-overlay:after {
        content: 'col';
    }

    .contact__upeco-group {
        flex-direction: column;
        align-items: flex-start;
    }

    .contact__upeco-group p {
        margin-top: 15px;
    }
}

@media screen and (-ms-high-contrast: none) {

    a.animated-box:hover,
    .active-btn {
        background: none;
        animation: none;
        color: #f0e947;
    }

    .animated-box:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: none;
        background-size: auto;
        clip-path: none;
        -webkit-clip-path: none;
        border: 2px solid #24a947;
    }

    .animated-box.in:after {
        animation: none;
    }

}

@media (min-width: 576px) {
    .t1s1 {
        font-size: 24px;
    }

    .ttm {
        left: 88px;
        top: 260px
    }

    .size-overlay:after {
        content: 'col-sm';
    }

    .tec p:nth-child(1) {
        top: 45%;
    }
}

@media (min-width: 675px) {
    .modal .product-overlay {
        background-size: cover;
    }
}

@media (min-width: 768px) {
    .size-overlay:after {
        content: 'col-md';
    }

    .ttm {
        left: 0;
    }

    .t1s1 {
        font-size: 36px;
    }

    .tec p:nth-child(1) {
        top: 40%;
        font-size: 20px;
    }

    .tec p:nth-child(2) {
        bottom: 30%;
    }
}

@media screen and (min-width: 991px) {
    .cat-item p {
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


@media (min-width: 992px) {
    .size-overlay:after {
        content: 'col-lg';
    }

    .ttm {
        position: absolute;
        top: 278px;
        left: 238px;
        width: 723px;
        height: 568px;
    }

    .t1s1 {
        font-size: 44px;
    }

    .tec p:nth-child(1) {
        top: 37%;
        left: 40px;
        font-size: 20px;
    }

    .tec p:nth-child(2) {
        bottom: 40px;
        left: 40px;
    }

    .tec p:nth-child(2) a {
        font-size: 16px;
    }

    .tech h3 {
        font-size: 32px;
        line-height: 39px;
        padding-bottom: 20px;
        padding-top: 70px;
    }

    .tech .footnote {
        bottom: 25px;
    }

    .tech-3 .tech-yellow-block,
    .tech-4 .tech-yellow-block {
        text-align: center;
    }

    .tech-yellow-block-4 {
        margin-top: 23px !important;
        margin-bottom: 128px !important;
    }

    .tech-4 .footnote {
        bottom: 60px;
    }

    .tech .tech-yellow-block {
        padding: 29px 40px;
        margin-bottom: 70px;
    }

}

@media (min-width: 1200px) {
    .size-overlay:after {
        content: 'col-xl';
    }

    .t1s1 {
        font-size: 48px;
    }

    .tec p:nth-child(1) {
        font-size: 24px;
    }

    .tec p:nth-child(2) a {
        font-size: 18px;
    }

    .tech-1 p {
        width: 940px;
    }

    .f5-letuch {
        background-position-x: 60%
    }
}