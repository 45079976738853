// Тут вперемешку старые стили проекта - media + origin и все остальные новые scss

@import "./origin.scss";


// @import "./components/*";
@import "./components/navigation";
@import "./components/certificates";
@import "./components/jumbotron";
@import "./components/products";
@import "./components/rewards";


@import "./media.scss";
 
.h3 {
    font-weight: 500;
    font-size: 48px;
    line-height: 59px;
    color: #F0E947;
}

.border-accent{
    border: 3px solid #000;
    border-image: linear-gradient(90deg,#f0e947,#24a947);
    border-image-slice: 1;
}
 
.btn {
    &--accent {
        --gradient-opacity: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        text-transform: uppercase;
        border: 3px solid black;
        border-image: linear-gradient(90deg, #F0E947 0%, #24A947 100%);
        border-image-slice: 1;
        position: relative;
        z-index: 1;

        &:hover {
            --gradient-opacity: 1;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, rgba(240, 233, 71, 1) 0%, rgba(36, 169, 71, 1) 100%);
            opacity: var(--gradient-opacity);
            transition: .3s ease-in-out;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }
    &--secondary {
        --gradient-opacity: 0;
        font-style: normal; 
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        text-transform: uppercase;
        border: 3px solid black;
        border-image: linear-gradient(90deg, #009BA5 0%, #064178 100%);
        border-image-slice: 1;
        position: relative;
        z-index: 1;

        &:hover {
            --gradient-opacity: 1;
            color: white;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, #009BA5 0%, #064178 100%);
            opacity: var(--gradient-opacity);
            transition: .3s ease-in-out;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }

    &--yellow-link {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: #F0E947;
        text-decoration-line: underline;

        &:hover {
            color: #FFFFFF;
        }
    }
}

.text-yellow{
    color: #F0E947;
}

.eco-accent {
    background-color: #60B442;
    border-radius: 4px;
    padding: 0 5px;
    display: inline-block;
}

// Always in end
@import "./media.scss";