[data-product-slide]:not([data-product-slide="0"]) {
    display: none;
}

.products {
    position: relative;
    overflow: hidden;

    &-series {
        margin: 45px auto 0;
        display: flex;
        align-items: stretch;
        justify-content: center;
        gap: 15px;
        max-width: 1150px;
        position: relative;
        z-index: 2;

        &__item {
            padding: 20px;
            border: 3px solid #000;
            border-image: linear-gradient(90deg, #F0E947 0%, #24A947 100%);
            border-image-slice: 1;
            max-width: 273px;
            width: 100%;
            background-image: url("/images/product-series.webp");
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            cursor: pointer;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-height: 300px;

            &>* {
                pointer-events: none;
                user-select: none;
            }
        }

        &__arrow {
            position: absolute;
            bottom: 22px;
            left: 26px;
            width: 10px;
            height: 18px;
            background-image: url("/images/arrow.webp");
            background-size: cover;
            transform: rotateZ(180deg);
        }

        &__image {
            display: flex;
            justify-content: flex-end;
            position: relative;
            z-index: 2;
        }

        &__gradient {
            background: radial-gradient(51.62% 45.44% at 51.99% 50%, #53d616 0, rgba(26, 28, 24, 0) 100%);
            height: 20em;
            left: -2em;
            position: absolute;
            bottom: -4em;
            width: 20em;
            z-index: 1;
        }

        &__title {
            font-size: 18px;
            line-height: 22px;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 5px;
            z-index: 2;
            position: relative;
        }

        &--mobile {
            [aria-expanded='true'] {
                .products-series__arrow {
                    transform: rotate(270deg);

                }

                .products-series__gradient {
                    background: radial-gradient(51.62% 45.44% at 51.99% 50%, rgb(240, 233, 71) 0%, rgba(26, 28, 24, 0) 100%);
                }
            }
        }
    }

    &-items {
        margin: 54px auto;
        display: none;

        &__container {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
        }

        &__swiper {
            &-buttons {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 15px;

                .swiper-button-next,
                .swiper-button-prev {
                    position: relative;
                    width: 8px;
                    height: 15px;
                    background-image: url("/images/ui-carousel-retailers/b6-carousel-next-hover.webp");
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin-top: 0;

                    &::after {
                        display: none;
                    }
                }

                .swiper-button-next {
                    right: auto;
                }

                .swiper-button-prev {
                    transform: scale(-1, 1);
                    left: auto;
                    margin-right: 42px;
                }

                @media (min-width: 768px) {
                    position: absolute;
                    width: 100%;
                    justify-content: space-between;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);

                    .swiper-button-next{
                        transform: translateX(25px) scale(4);
                    }
                    .swiper-button-prev {
                        transform: translateX(-25px) scale(-4);
                        margin-right: 0;
                    }
                }
            }
        }

        &--swiper {
            max-width: 100%;

            .swiper-slide {
                width: fit-content;
                display: flex;
                align-items: center;
                justify-content: center;
                height: auto;
            }

            .products {
                &-item {
                    height: 100%;

                    &__image {
                        &:before {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    &-item {
        max-width: 175px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
        position: relative;
        z-index: 1;
        cursor: pointer;

        &__title {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            margin: 25px 0;
            text-align: center;
        }

        &__image {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;

            &--accent {
                &::after {
                    content: "";
                    height: 110%;
                    left: -10px;
                    position: absolute;
                    top: 0px;
                    width: 140%;
                    z-index: -1;
                    background-image: url("/images/products-item__image--accent.webp");
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }

            img {
                // max-height: 280px;
                object-fit: contain;
                object-position: bottom;
            }
        }

        &::before {
            content: "";
            background: radial-gradient(69% 57% at 50% 53%, #60B442 0%, rgba(26, 28, 24, 0) 65%);
            // background: radial-gradient(49.41% 38.59% at 51.42% 46.12%,rgba(240,233,71,0.76) 0,rgba(26,28,24,0) 85%);
            height: 460px;
            position: absolute;
            width: 220px;
            z-index: -1;
            bottom: 10%;
            opacity: 0;
            transition: .3s ease-in-out;
        }

        &:hover {
            &::before {
                opacity: 1;
            }
        }

        &__button {
            z-index: 2;
        }
    }

    &-content {
        position: relative;
        z-index: 1;
        padding: 38px 0;
        margin-top: 30px;
        transform-origin: top;
        display: none;


        &__close {
            text-align: right;
            cursor: pointer;
        }

        &__subtitle,
        &__title {
            font-weight: 600;
            font-size: 30px;
            line-height: 39px;
            color: #FFFFFF;


            span {
                font-size: 18px;
                line-height: 21px;
                font-weight: 600;
            }
        }

        &__title {
            text-transform: uppercase;


        }

        &__subtitle {
            padding-bottom: 12px;
            border-bottom: 3px solid #000;
            border-image: linear-gradient(90deg, #f0e947, #24a947);
            border-image-slice: 1;
            margin-bottom: 20px;
        }

        &__accent {
            font-weight: 800;
            font-size: 24px;
            line-height: 30px;
            color: #F0E947;
            margin-bottom: 20px;
        }

        &__list {
            padding: 0;
            margin: 0;
            margin-bottom: 20px;

            li {
                padding: 0;
                margin: 0;
                position: relative;
                padding-left: 60px;
                list-style-type: none;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 25px;

                &::before {
                    content: "";
                    position: absolute;
                    width: 30px;
                    height: 3px;
                    background-color: #F0E947;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &--small {
                li {
                    font-size: 16px;
                    line-height: 16px;
                    margin-bottom: 0;
                    padding-left: 35px;
                }
            }
        }

        &__tip {
            padding: 15px 20px;
            font-weight: 700;
            font-size: 12px;
            line-height: 100%;
            color: #F0E947;
            margin-bottom: 30px;


            p:not(:last-child) {
                padding-bottom: 5px;
            }
        }

        &__description {
            background-color: rgba(36, 169, 71, .3);
            padding: 20px 23px;
            display: grid;
            grid-template-columns: repeat(2, auto);
            align-items: center;
            justify-content: space-between;

            &-flex {
                display: flex;
                gap: 30px;
            }

            &-text {
                color: #fff;

                h3 {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 29px;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                    text-align-last: left;
                    padding-top: 0;
                }

                p {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;

                    &:not(:last-child) {
                        padding-bottom: 15px;
                    }
                }
            }

            &-image {
                padding-left: 5px;

                img {
                    max-width: 100%;
                }

                figcaption {
                    font-size: 12px;
                    line-height: 12px;
                }
            }
        }

        &__shops {
            &-title {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                margin: 50px 0;
            }

            &-items {
                display: grid;
                grid-template-columns: repeat(1, auto);
                gap: 30px;
                justify-content: center;

                a {
                    img {
                        max-width: 175px;
                        width: 100%;
                        filter: grayscale(1);

                        &:hover {
                            filter: grayscale(0);
                        }
                    }
                }
            }
        }

        &__gallery {
            &>img {
                max-width: 100%;
            }
        }

        &__video {
            margin-top: 20px;

            video {
                max-width: 100%;
                width: 100%;
            }
        }

        &__slider {
            &-main {
                .swiper-slide {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    height: auto;

                    img {
                        max-width: 100%;
                    }
                }
            }

            &-nav {
                margin-top: 50px;
                padding: 0 35px;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: radial-gradient(70% 70% at 50% 53%, #60b442 0, rgba(26, 28, 24, 0) 65%);
                    left: 0;
                }

                // .swiper-slide:not(.swiper-slide-visible, .swiper-slide-next){
                //     opacity: 0;
                // }

                // .swiper-slide:not(.swiper-slide-visible){
                .swiper-slide:not(.swiper-slide-active, .swiper-slide-active~.swiper-slide-next) {
                    opacity: 0;
                }

                .swiper-slide {
                    display: flex;
                    align-items: end;
                    justify-content: center;
                    transition: .3s ease-in-out;
                    height: auto;

                    img {
                        max-width: 5em;
                    }

                    img.smaller {
                        max-width: 3em;
                    }
                }

                .swiper-button-next,
                .swiper-rtl .swiper-button-prev {
                    right: 0;
                }

                .swiper-button-prev,
                .swiper-rtl .swiper-button-next {
                    left: 0;
                }

                .swiper-button-next:after,
                .swiper-button-prev:after {
                    content: "";
                    width: 30px;
                    height: 53px;
                    background-image: url("/images/ui-carousel-retailers/b6-carousel-prev-hover.webp");
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                .swiper-button-next:after {
                    transform: scale(-1, 1);
                }
            }
        }

        &__note {
            opacity: .6;
        }

        &::before {
            content: "";
            position: absolute;
            width: 100vw;
            height: 100%;
            background-image: url('/images/product-content-bg.webp');
            background-size: cover;
            background-position: top;
            z-index: -1;
            top: 0;
            left: calc((100vw - 100%) / -2)
        }
    }
}

@media(max-width: 1199px) {
    .products {
        &-series {
            padding: 0 15px;
            justify-content: space-around;

            &__item {
                max-width: 200px;
            }

            &__title {
                font-size: 14px;
                font-weight: 500;
            }

            &__image {
                img {
                    max-width: 100%;
                }
            }
        }

        &-item {
            max-width: 140px;

            &__title {
                font-size: 9px;
                line-height: 11px;
                font-weight: 700;
                margin: 10px 0;
            }

            &__image {
                img {
                    max-width: 100%;
                }
            }

            &__button {
                .btn {
                    font-size: 10px;
                    line-height: 12px;
                }
            }
        }
    }
}

@media(max-width: 991px) {
    .products {

        &-series {
            flex-direction: column;
            gap: 30px;
            align-items: center;

            &__title {
                width: 100%;
            }


            &__item {
                max-width: 100%;
                flex-direction: row;
                padding: 11px;
            }

            &__gradient {
                bottom: 50%;
                transform: translateY(50%);
                right: -2em;
                left: auto;
            }

            &__arrow {
                left: 12px;
                bottom: 14px;
            }
        }

        &-content {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 11;
            padding: 38px 15px;
            max-width: 100%;
            overflow: auto;
            height: 100%;
            margin-top: 0;

            &__close {
                position: fixed;
                right: 30px;
                top: 30px;
                z-index: 12;
            }

            &__gallery {
                margin-bottom: 20px;

                img {
                    max-height: 350px;
                    display: block;
                    margin: 0 auto;
                }
            }

            &__description {
                grid-template-columns: repeat(1, auto);

                &-text {
                    margin-bottom: 15px;
                }

                &-image {
                    max-width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &::before {
                position: fixed;
            }
        }

        &-item {
            &::before {
                height: 100%;
                width: 160%;
            }

            &__title {
                min-height: 22px;
            }
        }

        &__list {
            li {
                font-size: 15px;
                line-height: 18px;
            }
        }
    }
}

@media(max-width: 768px) {
    .products{
        &-content{
            &__description{
                &-image{
                    flex-direction: column;
                }
            }
        }
    }
}