.jumbotron {
    &-head {
        margin: 0;
        position: relative;
        overflow: hidden;

        // Old variant -> now .slide-bg
        // &::before {
        //     content: "";
        //     position: absolute;
        //     background-image: url("../../images/background-b1.webp");
        //     background-position: bottom;
        //     background-size: cover;
        //     width: 100%;
        //     height: 100%;
        // }
    }

    &-nav {
        position: absolute;
        bottom: 50px;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
    }

    &__slider {
        .swiper-slide {
            // padding-top: 160px;
            height: auto;
        }

        &-button {

            display: flex;
            align-items: center;
            justify-content: center;
            gap: 22px;

            &-next,
            &-prev {
                background-image: url("/images/arrow.webp");
                width: 10px;
                height: 16px;
                cursor: pointer;
            }

            &-next {
                transform: scale(-1, 1);
            }
        }

        &-progressbar {
            --w-step: 0;

            width: 158px;
            height: 2px;
            background-color: #747474;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: var(--w-step);
                background-color: #F0E947;
            }
        }

        &-pagination {
            width: auto;

            span {
                color: #fff;
                font-weight: 500;

                &.swiper-pagination-current {
                    font-size: 24px;
                    line-height: 29px;
                }

                &.swiper-pagination-total {
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }
    }

}

.slide {

    &-bg {
        background-image: url("../../images/slider-bg.webp");
        background-position: bottom;
        background-size: cover;

        padding-top: 152px;
        padding-bottom: 70px;
    }

    &-safety{
        background-image: url("../../images/slider-safety-bg.webp");
        background-position: bottom;
        background-size: cover;

        height: 100%;

        &__title{
            color: rgba(255, 255, 255, 0.96);
            font-size: 37px;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;

            margin-bottom: 40px;
        }

        &__description{
            color: rgba(255, 255, 255, 0.96);
            font-size: 18px;
            font-weight: 500;
            line-height: 25px; /* 138.889% */

            margin-bottom: 70px;
        }

        &__buttons{
            display: flex;
            justify-content: space-between;

            width: 100%;
        }

        &__image{
            img{
                max-width: 700px;
            }
        }
    }

    &-drops {

        &::before,
        &::after {
            content: "";
            background-image: url("../../images/drops-bg.webp");
            background-size: cover;
            background-position: center;
            height: 100%;
            width: 20%;
            position: absolute;
            top: 0;
            opacity: 1;
            transition: .3s ease-in-out;
        }

        &::before {
            left: 0;
        }

        &::after {
            right: 0;
            transform: scale(-1, 1);
        }
    }

    &-organic-fertilizers {

        align-items: center;
        display: flex;
        height: 100%;

        &__title {
            color: #f0e947;
            letter-spacing: 0;
            padding-top: 45px;
            font-size: 45px;
            letter-spacing: .04rem;
            line-height: 52px;
        }

        &__button {
            margin-top: 58px;
        }

        &__image {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;

            &::before {
                background: radial-gradient(at 50% 50%, #f0e947 0, rgba(26, 28, 24, 0) 65%);
                content: "";
                height: 110%;
                left: 0;
                margin: 0% 0 0 -25%;
                position: absolute;
                top: 0;
                width: 150%;
                z-index: -1;
            }
        }

    }

    &-fertilizers {

        display: flex;
        height: 100%;
        align-items: center;

        &__new {
            font-weight: 700;
            font-size: 34px;
            line-height: 41px;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: #E6E044;
            margin-bottom: 3px;
        }

        &__title {
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 49px;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.96);
            margin-bottom: 40px;
        }

        &__list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 110px;
            row-gap: 12px;
            margin-bottom: 37px;
        }

        &__item {
            display: flex;
            align-items: center;
        }

        &-text {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #FFFFFF;
            margin-left: 15px;
        }

        &_epilogue {
            margin-bottom: 13px;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-transform: uppercase;
            color: rgba(240, 233, 71, 0.96);

            &.last {
                margin-bottom: 38px;
            }
        }

        &__image {
            position: relative;
            z-index: 1;

            img {
                max-width: 100%;
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: radial-gradient(at 50% 50%, #F0E947 0%, rgba(26, 28, 24, 0) 65%);
                height: 130%;
                width: 150%;
                margin: -25% 0 0 -25%;
                z-index: -1;
            }
        }
    }

    &-collection {

        display: flex;
        height: 100%;
        align-items: center;

        &__title {
            font-weight: 500;
            font-size: 40px;
            line-height: 49px;
            text-transform: uppercase;
            color: #FFFFFF;
            margin-bottom: 38px;
        }

        &__new {
            font-weight: 500;
            font-size: 60px;
            line-height: 73px;
            text-transform: uppercase;
            color: #F0E947;
            margin-bottom: 37px;
            
            span{
                padding: 10px;
                display: block;
                width: fit-content;
            }
        }

        &__image {
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: radial-gradient(at 50% 50%, #F0E947 0%, rgba(26, 28, 24, 0) 65%);
                height: 130%;
                width: 150%;
                margin: -15% 0 0 -35%;
                z-index: -1;
            }

            img {
                max-width: 727px;
            }
        }
    }

    &-antirain {
        align-items: center;
        display: flex;
        height: 100%;

        &__title {
            font-weight: 500;
            font-size: 48px;
            line-height: 59px;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.96);
            margin-bottom: 25px;
        }

        &__subtitle {
            font-weight: 500;
            font-size: 36px;
            line-height: 44px;
            color: rgba(240, 233, 71, 0.96);
            margin-bottom: 35px;
        }

        &__list {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            margin-top: 100px;
            padding: 30px 0;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 4px;
                height: 100%;
                background: linear-gradient(180deg, rgba(240, 233, 71, 0) 0%, #F0E947 47.13%, rgba(240, 233, 71, 0) 100%);
            }

            &-item {
                display: flex;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    height: 2px;
                    width: 70px;
                    background: #F0E947;
                    left: -70px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:nth-child(1) {
                    margin-bottom: 40px;
                }

                &:nth-child(2) {
                    margin-bottom: 70px;
                }

                span {
                    margin-top: 10px;
                    margin-left: 25px;
                }
            }
        }

        &__image {
            position: relative;
            z-index: 1;

            &::before {
                content: "";
                background: radial-gradient(at 50% 50%, #f0e947 0, rgba(26, 28, 24, 0) 65%);
                height: 80%;
                left: 50%;
                margin: 0% 0 0 -95%;
                position: absolute;
                bottom: 0;
                width: 180%;
                z-index: -1;
            }
        }
    }

    &-bioactivators {
        height: 100%;

        .row,
        .container {
            height: 100%;
        }

        &__title {
            color: hsla(0, 0%, 100%, .96);
            font-size: 37px;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 40px;
            padding-top: 30%;
            text-transform: uppercase;
        }

        &__subtitle {
            font-size: 45px;
            font-weight: medium;
            text-transform: uppercase;
        }

        &__image {
            &:before {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                background: radial-gradient(at 50% 50%, #f0e947 0, rgba(26, 28, 24, 0) 65%);
                z-index: 0;
            }
            img {
                max-width: 100%;
                position: relative;
                z-index: 1;
            }
        }
    }

    &-blockages {
        height: 100%;
        .row,
        .container {
            height: 100%;
        }

        &__subtitle {
            font-size: 45px;
            line-height: 1.2;
            font-weight: medium;
            text-transform: uppercase;
            padding-top: 30%;
        }

        &__image {
            img {
                max-width: 100%;
            }
        }

        &__buttons {
            margin-top: 87px;

            .btn {
                padding: 20px;
            }
        }
    }
}


@media(max-width: 1600px) {
    .slide {

        &-collection,
        &-organic-fertilizers,
        &-fertilizers,
        &-antirain {
            padding-top: 8em;
        }
    }
}

@media(max-width: 1440px) {
    .slide {
        &-collection {

            &__new,
            &__title {
                font-size: 44px;
                line-height: 44px;
            }

            &__image {
                justify-content: end;

                img {
                    width: 140%;
                }
            }
        }
    }
}

@media(max-width: 1199px) {
    .slide {
        &-fertilizers {
            &__new {
                font-size: 24px;
                line-height: 30px;
            }

            &__title {
                font-size: 20px;
                line-height: 23px;
            }

            &__list {
                column-gap: 10px;
            }

            &_epilogue {
                font-size: 14px;
                line-height: 24px;
            }
        }

        &-organic-fertilizers {
            &__title {
                font-size: 24px;
                line-height: 30px;
            }

            &__image {
                justify-content: end;

                img {
                    max-width: 100%;
                }
            }
        }

        &-collection {

            &__new,
            &__title {
                font-size: 24px;
                line-height: 30px;
            }
        }

        &-antirain {

            &__title,
            &__subtitle {
                font-size: 24px;
                line-height: 30px;
            }

            &__list {
                display: none;
            }

            &__image {
                width: 13em;

                img {
                    max-width: 100%;
                }
            }
        }

        &-bioactivators {
            &__subtitle {
                font-size: 24px;
                line-height: 1.2;
            }
        }

        &-blockages {
            &__subtitle {
                font-size: 24px;
                line-height: 1.2;
            }
        }
    }
}

@media(max-width: 991px) {
    .jumbotron {
        &-nav {
            bottom: 2em;
        }

        &__slider {
            &-button {
                display: none; 
            }

            &-progressbar {
                width: 73px;
                height: 4px;
            }

            &-pagination {
                span {
                    &.swiper-pagination-current {
                        font-size: 18px;
                        line-height: 21px;
                    }

                    &.swiper-pagination-total {
                        font-size: 14px;
                        line-height: 17px;
                    }
                }
            }
        }

        &-head{
            margin-top: 57px;
        }
    }

    .slide {

        &-safety,
        &-collection,
        &-organic-fertilizers,
        &-fertilizers,
        &-antirain {
            padding-top: 60px;
            padding-bottom: 30px;
        }

        &-safety{
            &__title{
                font-size: 14px;
            }
            &__description{
                font-size: 14px;
                margin-bottom: 40px;
            }
            &__buttons{
                flex-direction: column;
                gap: 15px;
            }

            &__image {
                img {
                    max-width: 100%;
                }
            }
        }

        &-collection {
            &__new {
                font-size: 21px;
                line-height: 26px;
            }

            &__title {
                margin-bottom: 35px;
                font-size: 16px;
                line-height: 20px;
            }

            &__list {
                &-item {
                    font-size: 12px;
                    line-height: 15px;
                }
            }

            &_epilogue {
                font-size: 10px;
                line-height: 12px;
            }
        }

        &-fertilizers {
            &__new {
                font-size: 20px;
                margin-bottom: 3px;
            }

            &__title {
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 10px;
            }

            &__list {
                grid-template-columns: repeat(1, 1fr);
                margin-bottom: 10px;
                row-gap: 6px;
            }

            &-text {
                font-size: 14px;
            }

            &_epilogue {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 13px;

                &.last {
                    margin-bottom: 10px;
                }
            }

            &__button {
                display: none;
            }
        }
    }
}

@media(max-width: 767px) {
    .jumbotron {
        &-head{
            margin-bottom: 0;
        }
        &-nav {
            bottom: 1em;
            left: 1em;
        }
    }

    .slide {
        &-fertilizers {
            &__content {
                position: relative;
                z-index: 2;
                width: 13em;
            }

            &_epilogue {
                font-size: 10px;
                line-height: 12px;
            }

            &-text {
                font-size: 12px;
                line-height: 15px;
            }

            &__image {
                bottom: 0;
                position: absolute;
                right: -7em;
                width: 20em;
                z-index: 1;
            }
        }

        &-organic-fertilizers {
            &__title {
                font-size: 16px;
                line-height: auto;
                padding-top: 15px;
            }

            &__button {
                margin-top: 0;
            }

            &__image {
                width: 20em;
            }
        }

        &-collection {
            &__title {
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 15px;
            }

            &__new {
                font-size: 21px;
                line-height: 26px;
                margin-bottom: 15px;
            }

            &__image{
                // width: 13em;
                justify-content: flex-start;

                img{
                    width: 100%;
                }
            }

            &__button{
                padding-bottom: 5px;
            }
        }

        &-antirain{
            &__content{
                position: relative;
                z-index: 2;
                width: 14em;
            }
            &__title,
            &__subtitle{
                font-size: 16px;
                line-height: normal;
                margin-bottom: 5px;
            }

            &__list{
                margin: 0; 
                padding: 0;

                margin-bottom: 5px;

                &-item{
                    img{
                        width: 3em;
                        height: 3em;
                        object-fit: contain;
                    }
                    span{
                        margin: 0;
                        font-size: 14px;
                    }

                    &::before{
                        display: none;
                    }

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3){
                        margin-bottom: 0;
                    }
                }

                &::before{
                    display: none;
                }
            }

            &__image{
                position: absolute;
                bottom: 0px;
                width: 8em;
                right: 0;
                z-index: 1;
            }
        }

        &-blockages {
            padding-top: 50px;
            height: auto;

            .row,
            .container {
                height: auto;
            }

            &__subtitle {
                padding-top: 0;
            }

            &__buttons {
                margin-top: 25px;
            }
        }

        &-bioactivators {
            padding-top: 50px;
            height: auto;

            .row,
            .container {
                height: auto;
            }

            &__title {
                padding-top: 0;
                font-size: 28px;
            }
        }
    }
}