.certificates {
    position: relative;
    padding-left: 50px;
    padding-right: 50px;

    &::before{
        content: "";
        position: absolute;
        width: 99.5vw;
        height: 100%;
        left: calc((100vw - 100%) / -2);
        top: 0;
        background-image: url('/images/background-b1.webp');
        background-size: cover;
        background-repeat: no-repeat;
        opacity: .4;
    }

    &__title {
        margin-bottom: 25px;
    }

    &__description {
        p {
            font-weight: 500;
            font-size: 15px;
            line-height: 121.5%;
            text-align: justify;
            color: #FFFFFF;
            margin-bottom: 35px;
        }

        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 121.5%;
            color: #666666;
        }
    }

    &__row {
        margin-top: 25px;
        justify-content: space-between;
        display: grid;
        grid-template-columns: repeat(3, 270px);
        gap: 75px;
    }

    &__col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__header {
        margin-bottom: 50px;

        &-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #FFFFFF;
        }

        &-subtitle {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #666666;
        }

        &-hr {
            display: block;
            width: 115px;
            height: 3px;
            background: linear-gradient(90deg, #F0E947 0%, #24A947 100%);
            margin: 20px 0;
        }
    }

    &__button {
        &-all {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
        }
    }

    &__slider {
        img {
            max-width: 100%;
        }

        &-nav {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            gap: 15px;
        }

        &-button-prev,
        &-button-next {
            background-image: url('../../images/arrow.webp');
            width: 10px;
            height: 16px;
            cursor: pointer;
        }

        &-button-next {
            transform: scale(-1, 1);
        }

        &-pagination {
            width: auto;

            span {
                font-weight: 500;
                color: #FFFFFF;

                &.swiper-pagination-current {
                    font-size: 24px;
                    line-height: 29px;
                }

                &.swiper-pagination-total {
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }
    }


}

@media(max-width: 992px) {
    .certificates {
        &__row {
            grid-template-columns: repeat(1, 100%);
        }
    }
}

@media(max-width: 768px) {
    .certificates {
        padding-left: 15px;
        padding-right: 15px;

        &__header{
            margin-bottom: 30px;
        }

        &__row{
            gap: 35px;
        }

        &__description {
            p {
                font-size: 14px;
                line-height: 121.5%;
            }

            span {
                font-size: 10px;
                line-height: 121.5%;
            }
        }

        &__button-all{
            .btn{
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}